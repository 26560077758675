import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import TechTerms from '../components/TechTerms'
import Layout from '../components/Layout'

const TechTermsPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.meta_title}</title>
        <meta name='description' content={post.frontmatter.meta_description} />
      </Helmet>
      <TechTerms
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
    </Layout>
  )
}

TechTermsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TechTermsPage

export const techTermsPageQuery = graphql`
  query TechTermsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        meta_title
        meta_description
      }
    }
  }
`
