import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'

const RelatedSpan = styled.span`
  border-bottom: 1px solid blue;
  cursor: pointer;
`

export const RelatedComponent = ({ keywordEntry }) => {
  if (_.isEmpty(keywordEntry.related)) {
    return null
  }

  return (
    <>
      Related:{' '}
      {keywordEntry.related.map((rel, index) => {
        // TODO: these need to point to tool tips
        return (
          <React.Fragment key={index}>
            <RelatedSpan
              className='is-7 has-text-grey is-italic'
              data-customrh
              data-name={`${_.toLower(rel)}`}
            >
              {rel}
            </RelatedSpan>{' '}
          </React.Fragment>
        )
      })}
    </>
  )
}

/**
 * KeywordEntry is the format of the large array in "keywords.js"
 *
 * required:
 *    name
 *    isa
 *    info
 *
 * Might have:
 *    link
 *    related
 *
 *
 */
export const KeywordCard = ({ keywordEntry }) => {
  return (
    <div className='card'>
      <div className='card-content'>
        <div className='media'>
          <div className='media-content'>
            <p className='title is-4'>{keywordEntry.name}</p>
            <p className='subtitle is-7 has-text-grey is-italic'>
              {_.first(keywordEntry.isa)}
            </p>
          </div>
        </div>

        <div className='content'>
          {keywordEntry.info}
          {keywordEntry.link ? (
            <>
              <br />
              <a
                href={keywordEntry.link}
                target='_blank'
                rel='noopener noreferrer'
              >
                More info
              </a>
            </>
          ) : null}
          <br />
          <RelatedComponent keywordEntry={keywordEntry} />
        </div>
      </div>
    </div>
  )
}
