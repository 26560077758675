import React, { useState } from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { KEYWORDS_SORTED } from '../JobKeywordExplorer/keywords'
import {
  findKeywordEntry,
  filterKeywordPartial,
} from '../JobKeywordExplorer/utils'
import { KeywordCard } from '../JobKeywordExplorer/keywordCard'
import styled from 'styled-components'

// need this for tooltips
import ReactHintFactory from 'react-hint'
import 'react-hint/css/index.css'
const ReactHint = ReactHintFactory(React)

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    width: 44%;
    margin: 1rem;
  }

  @media only screen and (max-width: 700px) {
    > div {
      width: 100%;
    }
  }

  @media print {
    > div {
      width: 95%;
      margin-left: 2rem;
      page-break-inside: avoid;
      display: table;
    }
  }
`

const AlternateKeywordCard = ({ keyword }) => {
  return (
    <div className='card'>
      <header className='card-header'>
        <p className='card-header-title'>{keyword.name}</p>
      </header>
      <div className='card-content'>
        <div className='content'>{keyword.info}</div>
      </div>
    </div>
  )
}

const KeywordsComponent = ({ searchTerm }) => {
  let keywords = KEYWORDS_SORTED
  if (searchTerm) {
    keywords = filterKeywordPartial(searchTerm)
  }
  return (
    <CardsWrapper>
      {keywords.map(keyword => {
        return <KeywordCard key={keyword.name} keywordEntry={keyword} />
      })}
    </CardsWrapper>
  )
}

const PrintStylesWrapper = styled.div``

const TechTermsPageTemplate = ({
  title,
  description,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content

  const [searchTerm, setSearchTerm] = useState(null)

  return (
    <PrintStylesWrapper>
      <section className='hero is-primary is-bold no-print'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <h1 className='title has-text-white is-size-1'>{title}</h1>
                  <h2 className='description has-text-white is-size-3'>
                    {description}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section section--gradient'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-12'>
              <div className='buttons is-pulled-right no-print'>
                <button
                  className='button is-primary'
                  onClick={() => {
                    window.print()
                  }}
                >
                  Print 🖨️
                </button>
              </div>
              <div
                style={{ margin: '1rem', width: '90%', textAlign: 'center' }}
              >
                <div className='field no-print'>
                  <div className='control has-icons-left'>
                    <span className='icon is-medium is-left'>
                      <i className='fas fa-envelope'>🔎</i>
                    </span>
                    <input
                      className='input is-info'
                      type='text'
                      placeholder='Start typing a search term'
                      onChange={e => {
                        setSearchTerm(e.target.value)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='print-only'>
                <h2 className='description has-text-black is-size-3'>
                  Some technical terms you might find in job descriptions or
                  resumes for software engineering positions |
                  www.iteachrecruiters.com/tech-terms
                </h2>
              </div>
              <KeywordsComponent searchTerm={searchTerm} />
            </div>
          </div>
        </div>
      </section>
      <ReactHint
        autoPosition
        events
        attribute='data-customrh'
        onRenderContent={(target, content) => {
          // console.log("React hint", target, content);
          const { name } = target.dataset
          const keywordMatched = findKeywordEntry(name)
          if (!keywordMatched) {
            return (
              <div className='react-hint__content'>
                No details for this keyword
              </div>
            )
          }
          return (
            <div className='react-hint__content' style={{ maxWidth: '350px' }}>
              <h4 className='is-size-4 has-text-white-ter'>
                {keywordMatched.name}
              </h4>
              {keywordMatched.info}
            </div>
          )
        }}
      />
    </PrintStylesWrapper>
  )
}

TechTermsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default TechTermsPageTemplate
